import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [loading, setLoading] = useState(true);

  const introduction = [
    'IT・AI技術に精通し、AI開発・Web開発やSNSマーケティングをしています。',
    '主にWeb開発者として5年ほどの経験を積んでおり、AI技術は2022年末から本格的に導入しています。',
    'AI自動化ツールの開発や、SNSマーケティングを自分でも行なっている為、マーケ視点をもった開発が可能です。',
    '最新技術を取り入れる事によって他社との技術的差分を出すサポートが得意です。',
    '開発にもAIを活用している為、他社より工数を抑えることが可能です。',
    '東大・京大・筑波大の学生エンジニアも教育している為、開発リソースが多いです。',
  ];

  const apps = [
    {
      title: 'chuchu',
      description: [
        'ユーザー数10万人超え。',
        'Lineで自撮りを送るとAI美女化されるLineアプリ。',
        '東大・京大・名古屋大の学生を休学化',
        'エンジニアとして教育し、2ヶ月でWeb開発出来るようにしました。',
        '※現在は引き継ぎ済み',
      ],
      imgSrc: '/img/chuchu-ai.png',
      link: 'https://chuchu-lp.web.app/',
      buttonText: '詳細を見る',
      disabled: false
    },
    {
      title: 'AI写メ日記',
      description: [
        'Lineで簡単な単語を送ると風俗嬢の写メ日記用文章を自動生成するアプリ。',
        'ユーザー数5000人超え、国内最大ユーザー数の風俗嬢向け公式Line'
      ],
      imgSrc: '/img/ai-syame.png',
      link: 'https://lit.link/AlienTanaka',
      buttonText: '詳細を見る',
      disabled: false
    },
    {
      title: 'MagicLab',
      description: [
        '外部非公開の自社システム',
        '①スクレイピングやAPIでWeb上のデータを収集',
        '②AIが収集データを元に文章をリライト or 自動生成',
        '③リライト結果をSlackやLineに通知',
      ],
      imgSrc: '/img/magic-lab.png',
      buttonText: '自社内部システムの為、非公開',
      disabled: true
    },
    {
      title: 'UberWeed',
      description: [
        'UberEatsのCBD版',
        '最大17店舗加盟（自社運営7店舗）',
        '日本初のCBD配達プラットフォーム',
        'ユーザーと配達員の位置情報から配達予定時刻を出して配達依頼・オンライン購入が可能',
        '法改正により撤退',
      ],
      imgSrc: '/img/uberweed.png',
      buttonText: '閉鎖済み',
      disabled: true
    },
    {
      title: 'CBD Passport',
      description: [
        'CBDの法改正へ抗議するパブリックコメントを生成し提出まで完了するLineアプリ',
        '前述のUberWeedが封鎖される危機になり開発',
        '全国のCBDショップほぼ全てにLine追加用QRコードが付いたポスターを配備',
        'CBDユーザー・CBD事業者がメインユーザー',
      ],
      imgSrc: '/img/CBD-passport.png',
      link: 'https://lin.ee/GWS9f7b',
      buttonText: 'Line追加する',
      disabled: false,
    },
    {
      title: 'link-club',
      description: [
        'Twitterのカードをカスタマイズして使う生成マシーン',
        'リダイレクト先を設定して自由にカードをカスタム可能',
      ],
      imgSrc: '/img/link-me.png',
      link: 'https://www.link-me.club/',
      buttonText: 'Webサイトへ',
      disabled: false,
    }
  ];

  const skills = [
    { name: '開発PM', experience: '4年' },
    { name: 'React', experience: '2年' },
    { name: 'Next.js', experience: '2年' },
    { name: 'Vue.js', experience: '2年' },
    { name: 'Node.js', experience: '2年' },
    { name: 'Django', experience: '1年' },
    { name: 'Python', experience: '3年' },
    { name: 'PHP', experience: '3年' },
    { name: 'Laravel', experience: '3年' },
    { name: 'AWS', experience: '2年' },
    { name: 'GCP', experience: '2年' },
    { name: 'WordPress', experience: '1年' },
    { name: 'StableDiffusion', experience: '1年' },
    { name: 'DeepFake', experience: '半年' },
  ];

  const others = [
    'TikTok 200万再生',
    'Twitter 350万インプレッション',
    'SNS求人（月10件以上の応募）'
  ];

  useEffect(() => {
    // 星をランダムに生成する関数
    const createStars = () => {
      const starsContainer = document.querySelector('.stars-container');
      for (let i = 0; i < 100; i++) {
        const star = document.createElement('div');
        const size = Math.random() * 3 + 1; // ランダムなサイズ
        const xPosition = Math.random() * 100; // ランダムなX軸位置（%）
        const yPosition = Math.random() * 100; // ランダムなY軸位置（%）
        const duration = Math.random() * 5 + 5; // ランダムなアニメーションの長さ（5秒〜10秒）
        const delay = Math.random() * 3; // アニメーションの遅延時間

        // 星のスタイルを設定
        star.classList.add('star');
        star.style.width = `${size}px`;
        star.style.height = `${size}px`;
        star.style.top = `${yPosition}vh`;
        star.style.left = `${xPosition}vw`;
        star.style.animationDuration = `${duration}s`;
        star.style.animationDelay = `${delay}s`; // アニメーションの遅延を追加

        // 星をコンテナに追加
        starsContainer.appendChild(star);
      }
    };

    if (loading) {
      createStars();
    }

    const timer = setTimeout(() => {
      setLoading(false);
    }, 4000); // 4秒間のロードアニメーション
    return () => clearTimeout(timer);
  }, [loading]);

  return (
    <div className="App">
      {loading ? (
        <div className="space-bg">
          <div className="hashsh-text">hashsh.net</div>
          <div className="stars-container"></div> 
        </div>
      ) : (
        <div className="main-content bg-gray-100 text-black min-h-screen fade-in">
          <header className="flex justify-between items-center bg-black text-white p-4">
            <h1 className="text-4xl font-bold">hashsh.net</h1>
          </header>

          <main className="p-2">
            <section className="bg-white shadow-lg rounded-lg p-6 mb-8">
              <h2 className="text-3xl font-semibold mb-4">自己紹介</h2>
              {introduction.map((paragraph, index) => (
                <p key={index} className="text-lg leading-relaxed mb-4">
                  {paragraph}
                </p>
              ))}
            </section>

            <section className="bg-white shadow-lg rounded-lg p-6 mb-8">
              <h2 className="text-3xl font-semibold mb-6">運営アプリ</h2>
              <p className="text-sm text-gray-500 mb-4">※自社運営のみ(閉鎖済みも含む)<br/>※依頼されて開発したものは含みません。</p>

              <div className="relative">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 overflow-x-scroll md:overflow-auto">
                  {apps.map((app, index) => (
                    <div key={index} className="bg-gray-900 text-white rounded-lg shadow-lg p-6">
                      <div className="w-full h-48 bg-gray-700 rounded-lg">
                        <img src={app.imgSrc} alt={app.title} className="w-full h-full object-cover rounded-lg" />
                      </div>
                      <div className="mt-4">
                        <h3 className="text-xl font-bold">{app.title}</h3>
                        <div className="text-md leading-relaxed mt-2">
                          {app.description.map((line, idx) => (
                            <p key={idx}>{line}</p>
                          ))}
                        </div>
                        {app.disabled ? (
                          <button disabled className="bg-gray-600 text-white py-2 px-4 rounded-lg mt-4 inline-block">{app.buttonText}</button>
                        ) : (
                          <a href={app.link} target="_blank" className="bg-white text-black py-2 px-4 rounded-lg mt-4 inline-block">{app.buttonText}</a>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>

            <section className="bg-white shadow-lg rounded-lg p-6 mb-8">
              <h2 className="text-3xl font-semibold mb-4">使用技術とスキル</h2>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {skills.map((skill, index) => (
                  <div key={index} className="bg-gray-200 text-black rounded-lg p-2 text-center shadow-md">
                    {skill.name} <br /> <span className="text-sm text-gray-600">経験年数: {skill.experience}</span>
                  </div>
                ))}
              </div>

              <h3 className="text-lg font-semibold mt-6">その他</h3>
              <ul className="list-disc ml-6 mt-2">
                {others.map((skill, index) => (
                  <li key={index}>{skill}</li>
                ))}
              </ul>
            </section>
          </main>

          <footer className="bg-black text-white p-6">
            <p>Email: hashsh.net@gmail.com</p>
          </footer>
        </div>
      )}
    </div>
  );
}

export default App;
